import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../store/redux/User/actions";
import Auth from "./Auth/Auth";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import SignupThanks from "./SignupThanks";
import { Link, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import Profile from "./Profile/Profile";
import AgencyProfile from "./AgencyProfile/AgencyProfile";
import Listings from "./Listings2";
import EListings from "./EListings";
import EPSSelect from "./EPSSelect";
import Tasks from "./Tasks";
import Reservations from "./Reservations";
import Partners from "./Partners";
import RUPartners from "./Partners-RU";
import BPPartners from "./Partners-BP";
import EPartners from "./EPartners";
// material ui
import Welcome from "./Welcome";
import Sidebar from "../components/Sidebar";
import SearchProperty from "./SearchProperty";
import ShubPanel from "./ShubPanel";
import Map from "./Map";
import Reports from "./Reports";
import Touch from "./Touch";
import Faq from "./Faq";
import HotDestinations from "./HotDestinations";
import Collections from "./Collections";
import WishList from "./WishList";
import Favorites from "./Favorites";
import Property from "./Property";
import PropertyEdit from "./PropertyEdit";
import PropertyReservePage from "./PropertyReserve";
import Admin from "./Admin";
import {
  PATH_PARTNERS,
  PATH_BP_PARTNERS,
  PATH_RU_PARTNERS,
  PATH_EPARTNERS,
  PATH_ADMIN,
  PATH_LISTINGS,
  PATH_SELECT,
  PATH_EPS_LISTINGS,
  PATH_TASKS,
  PATH_COLLECTIONS,
  PATH_FAQ,
  PATH_FAVORITES,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_HOT_DESTINATIONS,
  PATH_LOGIN,
  PATH_MAP,
  PATH_PROFILE,
  PATH_PROPERTY,
  PATH_PROPERTY_ID,
  PATH_PROPERTY_EDIT,
  PATH_PROPERTY_X,
  PATH_RESERVE,
  PATH_REPORTS,
  PATH_RESERVATIONS,
  PATH_SEARCH,
  PATH_SHUB,
  PATH_SIGNUP_THANKS,
  PATH_WISH_LIST,
  PATH_INTOUCH,
  PATH_Welcome,
  PATH_SIGNUP,
  PATH_CLIENTS,

} from "../Util/constants";
import AuthService from "../services/auth.service";
import { baseURL } from "../core";
import axios from "axios";
import Signup from "./Signup/Signup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerifyCodePage from "./ResetPasswordPage/VerifyCodePage";
import ResetChangePasswordPage from "./ResetPasswordPage/ResetChangePasswordPage";
import PropertyId from "./PropertyId";
const logintoken = localStorage.getItem('jToken');


export const drawerWidth = 240;
const changeFontColor = {
  color: "#44C8F5",
  fontWeight: "600",
};
const fontColor = { color: "#32516D" };

function MainPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [agent, setAgent] = useState(localStorage.getItem("agent"));
  const [agency, setAgency] = useState(localStorage.getItem("agency"));

  const user = useSelector((state) => state.user.user);
  const [profile, setProfile] = [];
  const [token, setToken] = useState(localStorage.getItem("jToken"));
  const [source, setSource] = useState(localStorage.getItem("channelSource"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [partner, setPartner] = useState(localStorage.getItem("partner"));
  const [property, setProperty] = useState(localStorage.getItem("property"));

  const regions_localstorage = localStorage.getItem('regions');
  const [regions, setRegions] = useState( (regions_localstorage && regions_localstorage !== 'undefined') ? JSON.parse(localStorage.getItem('regions')) : [])

  const destinations_localstorage = localStorage.getItem('destinations');
  const [destinations, setDestinations] = useState( (destinations_localstorage && destinations_localstorage !== 'undefined') ? JSON.parse(localStorage.getItem('destinations')) : [])

  const cities_localstorage = localStorage.getItem('cities');
  const [cities, setCities] = useState( (cities_localstorage && cities_localstorage !== 'undefined') ? JSON.parse(localStorage.getItem('cities')) : [])

  const countries_localstorage = localStorage.getItem('countries');
  const [countries, setCountries] = useState( (countries_localstorage && countries_localstorage !== 'undefined') ? JSON.parse(localStorage.getItem('countries')) : [])
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || 'USD');
  const [currencies, setCurrencies] = useState(localStorage.getItem("currencies"));

  const storedProperty = localStorage.getItem("property") && isValidJSON(localStorage.getItem("property")) ? JSON.parse(localStorage.getItem("property")) : {};
  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const userRequest = axios.create({
    baseURL: baseURL,
    headers: {
      token: `Bearer ${token}`,
    },
  });


  useEffect(() => {

    const fetchCurrencies = async () => {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get("https://api.triangle.luxury/xchange");
        const data = response.data;
        localStorage.setItem("currency", 'USD');
        localStorage.setItem("exchange", JSON.stringify(data));
        setCurrencies(data);
      }
      catch (error) {
        console.error("Error fetching currencies:", error);
      }
    }
    if (!currencies) {
      fetchCurrencies();
    }
    const agentInfo = JSON.parse(localStorage.getItem("agent") || "{}");
    const agentCurrency = agentInfo.currency || "USD";
    setCurrency(currency || agentCurrency || 'USD')
    localStorage.setItem("currency", currency || agentCurrency || 'USD');
    console.log('selected currency:', currency || agentCurrency || 'USD')
    console.log('fetchCurrencies()')
  }, [])

  const getProfile = async () => {
    const agentId = localStorage.getItem("agent_id") ? parseInt(localStorage.getItem("agent_id")) : null;
    if (agentId) {
      const res = await userRequest.get(`/agent/get-profile`, {
        params: {
          agent_id: agentId
        },
      });
      localStorage.setItem("agent", JSON.stringify(res.data?.agent));
      const agent = res?.data?.agent;
      console.log("read agent:", agent)
      const agencyID = parseInt(agent?.agency_id);
      console.log("TA=", agencyID);

      const TAres = await userRequest.get(`travel-agency/get-travel-agencies`, {
        params: {
          agency_id: agencyID,
        },
      });
      setAgency(TAres.data.agencies[0])
      console.log("read agency: ", agency)
      localStorage.setItem("travelAgency", JSON.stringify(agency));
    } else {
      history.push(PATH_LOGIN);
    }
  }


  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    AuthService.DestinationsOptions().then((response) => {
      if (response) {
        console.log('succesfully read shub destinations!')
      }
    }).catch((e) => {
      console.log(e)
      console.log('cuold not read shub destinations!')
    })
  }, []);

  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    if (screenSize < 800) {
      setActiveMenu(false);
      localStorage.setItem("screen", activeMenu);
      localStorage.setItem("screenSize", screenSize);
    } else {
      setActiveMenu(true);
      localStorage.setItem("screen", activeMenu);
      localStorage.setItem("screenSize", screenSize);
    }
  }, [screenSize]);

  const handleToggleMenu = () => {
    if (screenSize < 900) {
      setActiveMenu((preValue) => !preValue);
    } else { setActiveMenu(false) }
  };

  useEffect(() => {
    let urls = location.pathname ? location.pathname?.split("/") : [];
    // ReactGA.send({ hitType: "pageview", page: location.pathname });

    console.log(urls)
    if (storedProperty) {
      const { listing, xdata, fullCalendar } = storedProperty;
    }
    /*     if (!links) {
          if (
            location.pathname !== "/signup" &&
            location.pathname !== "/login" &&
            location.pathname !== "/forgotPassword" &&
            location.pathname !== "/faq" &&
            location.pathname !== "/intouch" &&
            location.pathname !== "/signupthanks"
          ) {
    
            if (location.pathname === `/reservations`) {
              history.push({
                pathname: `/reservations`,
                state: {
                  property: storedProperty?.listing,
                  xdata: storedProperty?.xdata,
                  fullCalendar: storedProperty?.fullCalendar,
                },
              });
            }
            if (location.pathname === `/verifycode/${urls[2]}`) {
              history.push(`/verifycode/${urls[2]}`);
            }
            if (location.pathname === `/resetpassword/${urls[2]}`) {
              history.push(`/resetpassword/${urls[2]}`);
            } */
    if (agent && agency && urls[1] === "property") {
      setPropertyId(urls[2]);
      // localStorage.setItem("propertyId", urls[2]);
      history.push(`/property`);
    }

    if (location.pathname === "/") {
      setTimeout(() => { //reset data and move to the login

        localStorage.removeItem("formerDestination");
        localStorage.removeItem("destination");
        localStorage.setItem("destination", "");
        localStorage.removeItem("dateFrom");
        localStorage.removeItem("dateTo");
        localStorage.removeItem("adults");
        localStorage.removeItem("children");
        localStorage.removeItem("bedrooms");
        localStorage.removeItem("bathrooms");
        localStorage.removeItem("propertyType");
        localStorage.removeItem("selectedTypes");
        localStorage.removeItem("selectedPrices");
        localStorage.removeItem("selectedMusthave");
        localStorage.removeItem("selectedAmenities");
        localStorage.removeItem("searchedAmenities");
        localStorage.removeItem("selectedPropertiesItem");
        localStorage.removeItem("searchedPriceRange");
        localStorage.removeItem("searchedTypes");
        localStorage.removeItem("searchedMustHave");
        localStorage.removeItem("sortBy");
        localStorage.removeItem("collections");
        localStorage.removeItem("clientEmail");
        // localStorage.removeItem("activeRatePlan");
        history.push("/welcome");

      }, 200);
    }
    if (logintoken === null) {
      history.push("/login");
    } else {
      //history.push(PATH_PARTNERS);
    }


  }, []);

  return (
    <>

      <Switch>
        <Route exact path={[PATH_LOGIN]}>
          <Auth stage="login" signup={false} setToken={setToken} />
        </Route>
        <Route exact path={"/verifycode/:id"}>
          <VerifyCodePage />
        </Route>
        <Route exact path={"/resetpassword/:id"}>
          <ResetChangePasswordPage />
        </Route>
        <Route exact path={[PATH_FORGOT_PASSWORD]}>
          <ResetPasswordPage />
        </Route>

        <Route exact path={[PATH_SIGNUP_THANKS]}>
          <SignupThanks />
        </Route>
        <Route path={[PATH_Welcome]}>
          <Welcome signup={true} />
        </Route>
        <Route path={[PATH_SIGNUP]}>
          <Signup />
        </Route>
        <Route path={[PATH_LISTINGS]}>
          <Listings
            agent={agent}
            agency={agency}
            token={token}
          />
        </Route>
        <Route path={[PATH_EPS_LISTINGS]}>
          <EListings
            agent={agent}
            agency={agency}
            token={token}
          />
          </Route>
          <Route path={[PATH_SELECT]}>
            <EPSSelect
              agent={agent}
              agency={agency}
              token={token}
            />
          </Route>
          <Route path={[PATH_TASKS]}>
            <Tasks
              agent={agent}
              agency={agency}
            />
          </Route>
          <Route path={PATH_PROPERTY}>
            <Property
              currency={currency}
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={PATH_PROPERTY_ID}>
            <PropertyId
              currency={currency}
              agency={agency}
              agent={agent}
              token={token}
              id={propertyId}
            />
          </Route>
          <Route exact path={[PATH_SEARCH]}>
            <SearchProperty
              agency={agency}
              agent={agent}
              token={token} />
          </Route>
          <Route exact path={[PATH_SHUB]}>
            <ShubPanel
              token={token}
              agent={agent}
              agency={agency}
            />
          </Route>
          <Route exact path={[PATH_MAP]}>
            <Map
              token={token}
              agent={agent}
              agency={agency}
            />
          </Route>
          <Route exact path={[PATH_COLLECTIONS]}>
            <Collections
              signup={false}
              token={token}
              agent={agent}
              agency={agency}
            />
          </Route>
          <Route exact path={[PATH_HOT_DESTINATIONS]}>
            <HotDestinations
              token={token}
              agent={agent}
              agency={agency} />
          </Route>
          <Route exact path={[PATH_FAVORITES]}>
            <Favorites
              token={token}
              agent={agent}
              agency={agency} />
          </Route>
          <Route exact path={[PATH_RESERVATIONS]}>
            <Reservations
              token={token}
              agent={agent}
              agency={agency} />
          </Route>
          <Route exact path={[PATH_REPORTS]}>
            <Reports />
          </Route>
          <Route exact path={[PATH_PROFILE]}>
            <Profile
              agency={agency}
              agent={agent}
              token={token}
              setAgent={setAgent}
              setAgency={setAgency}
            />
          </Route>
          <Route exact path={[PATH_WISH_LIST]}>
            <WishList
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={[PATH_INTOUCH]}>
            <Touch token={token}
              agent={agent}
              agency={agency}
            />
          </Route>
          <Route exact path={PATH_PROPERTY}>
            <Property
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={[PATH_PROPERTY_EDIT]}>
            <PropertyEdit
              propertyId={propertyId}
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={[PATH_FAQ]}>
            <Faq />
          </Route>
          <Route exact path={[PATH_ADMIN]}>
            <Admin
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={[PATH_RESERVE]}>
            <PropertyReservePage
              agency={agency}
              agent={agent}
              token={token}
            />
          </Route>
          <Route exact path={[PATH_PARTNERS]}>
            <Partners
              agency={agency}
              agent={agent}
              token={token}
              setProfile={setProfile}
              screenSize={screenSize}
              setScreenSize={setScreenSize}
              activeMenu={activeMenu}
              handleToggleMenu={handleToggleMenu}
              setActiveMenu={setActiveMenu}
            />
          </Route>
          <Route exact path={[PATH_BP_PARTNERS]}>
            <BPPartners
              agency={agency}
              agent={agent}
              token={token}
              setProfile={setProfile}
              screenSize={screenSize}
              setScreenSize={setScreenSize}
              activeMenu={activeMenu}
              handleToggleMenu={handleToggleMenu}
              setActiveMenu={setActiveMenu}
            />
          </Route>
          <Route exact path={[PATH_RU_PARTNERS]}>
            <RUPartners
              agency={agency}
              agent={agent}
              token={token}
              setProfile={setProfile}
              screenSize={screenSize}
              setScreenSize={setScreenSize}
              activeMenu={activeMenu}
              handleToggleMenu={handleToggleMenu}
              setActiveMenu={setActiveMenu}
            />
          </Route>
          <Route exact path={[PATH_EPARTNERS]}>
            <EPartners
              agency={agency}
              agent={agent}
              token={token}
              setProfile={setProfile}
              screenSize={screenSize}
              setScreenSize={setScreenSize}
              activeMenu={activeMenu}
              handleToggleMenu={handleToggleMenu}
              setActiveMenu={setActiveMenu}
            />
          </Route>
          <div className="page-container">
            <div className="page-header">S-Hub Control Panel</div>
            <Sidebar
              agency={agency}
              agent={agent}
              token={token}
              setProfile={setProfile}
              screenSize={screenSize}
              activeMenu={activeMenu}
              handleToggleMenu={handleToggleMenu}
            />
            <div className={`${"page-body"}`} onClick={() => screenSize < 768 && setActiveMenu((preValue) => false)} >


            </div>
          </div>
      </Switch >
      <ToastContainer />
    </>
  );
}
export default MainPage;
